/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import {
    shape, string, bool, object, func,
} from 'prop-types';

import mbpLogger from 'mbp-logger';

import Cookies from 'universal-cookie';
import { GRAPHQL_ENV } from '../../../gql';
import { setPassportSignInData } from '../../../../state/ducks/Passport/Passport-Action';
import findProductPageByPartNumber from '../../../gql/queries/findProductPageByPartNumber';
import { getBrand, getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getPassportData } from '../../../../state/ducks/Passport/Passport-Selectors';
import { getIsPassportUserLoggedIn } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';

const GraphqlPassportSignInContainer = ({
    passportData, brand, passportSignInData, featureFlags, isPassportUser,
}) => {
    const cookies = new Cookies();
    const persistedUserProfile = cookies.get('persisted_user_profile');
    let profile = {};
    if (persistedUserProfile && persistedUserProfile?.firstName && persistedUserProfile?.lastName) {
        const passportFlag = persistedUserProfile?.passportFlag;
        const passportStartDate = persistedUserProfile?.passportStartDate;
        const passportEndDate = persistedUserProfile?.passportEndDate;
        const expiredSubDetails = passportData?.passport_messaging?.user_with_expired_passport_subscription;
        const expiringSubDetails = passportData?.passport_messaging?.user_whose_passport_subscription_is_about_to_expire;
        const activePassportMembershipSignedIn = passportData?.passport_messaging?.active_passport_subscription_signed_in;
        const activePassportMembershipSignedOut = passportData?.passport_messaging?.active_passport_subscription_signed_out;
        const newUserDetails = passportData?.passport_messaging?.user_who_never_bought_passport_subscription;
        if (passportFlag && passportStartDate && passportEndDate) {
            const today = new Date().getTime();
            const expDate = new Date(passportEndDate).getTime();

            // active passport member SIGNED IN
            if (featureFlags['is-passport-conditional-offer-signed-in']
                && activePassportMembershipSignedIn?.passport_referenced_sku?.[0]?.item_sku
                && isPassportUser && expDate > today) {
                profile = {
                    type: 'activePassportMembershipSignedIn',
                    details: activePassportMembershipSignedIn,
                };
            }

            // expired account
            if (featureFlags['is-passport-conditional-offer-expired']
                && expiredSubDetails?.passport_referenced_sku?.[0]?.item_sku
                && expDate < today) {
                profile = {
                    type: 'expiredPassportMembership',
                    details: expiredSubDetails,
                };
            }

            // expiring account
            if (featureFlags['is-passport-conditional-offer-expiring']
                && expiringSubDetails?.passport_referenced_sku?.[0]?.item_sku
                && expDate > today) {
                const daysDifference = Math.ceil((expDate - today) / (1000 * 60 * 60 * 24));
                if (daysDifference <= expiringSubDetails?.number_of_days_from_expiration) {
                    profile = {
                        type: 'expiringPassportMembership',
                        details: expiringSubDetails,
                    };
                }
            }

            // active passport member SIGNED OUT
            if (featureFlags['is-passport-conditional-offer-signed-out']
                && activePassportMembershipSignedOut?.passport_referenced_sku?.[0]?.item_sku
                && !isPassportUser && expDate > today) {
                profile = {
                    type: 'activePassportMembershipSignedOut',
                    details: activePassportMembershipSignedOut,
                };
            }
        }

        // never had passport membership
        if (!passportFlag && !passportStartDate
            && !passportEndDate
            && featureFlags['is-passport-conditional-offer-new']
            && newUserDetails?.passport_referenced_sku?.[0]?.item_sku) {
            profile = {
                type: 'newUser',
                details: newUserDetails,
            };
        }
    }

    if (profile?.details?.passport_referenced_sku?.[0]?.item_sku) {
        let passportProfileData = {};
        const passport = profile.details.passport_referenced_sku[0];
        const PRODUCT_BY_PART = findProductPageByPartNumber(brand?.domain, passport.item_sku);
        const { error, data } = useQuery(PRODUCT_BY_PART);
        if (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                query: PRODUCT_BY_PART,
                component: 'GraphqlPassportSigninLogic.js',
                message: 'Error loading data from Graphql',
                env: GRAPHQL_ENV,
                error,
            });
            return null;
        }

        if (data) {
            const passportProducts = data?.findProductPageByPartNumber?.product;
            if (passportProducts?.productSkus?.length && passport.passport_item_sku) {
                passportProducts.productSkus.forEach((sku) => {
                    if (sku?.id === passport.passport_item_sku) {
                        const passportPage = passportData?.url;
                        const prices = sku?.prices || [];
                        prices.forEach((selectedSku) => {
                            if (selectedSku?.type === passport?.price_type) {
                                passportProfileData = {
                                    enable: true,
                                    underNavText: profile?.details?.under_nav_text,
                                    underNavLink: passportPage,
                                    type: profile.type,
                                    price: selectedSku.value,
                                    itemSku: sku?.id,
                                    passportEnrolmentTerm: passport?.term_for_enrolment_page,
                                    checkoutPageMessage: profile?.details?.checkout_page_message,
                                    productPageMessage: profile?.details?.message_for_pdpage,
                                    hideAtCheckout: {
                                        shippingValue: profile?.details?.hide_messaging_at_checkout?.maximum_shipping_value,
                                        itemsInCart: profile?.details?.hide_messaging_at_checkout?.total_number_of_items_in_cart,
                                    },
                                    mobileBanner: profile?.details?.mobile_banner_image_for_enrolment_page?.url,
                                    desktopBanner: profile?.details?.desktop_banner_image_for_enrolment_page?.url,
                                };
                            }
                        });
                    }
                });
            }
            passportSignInData(passportProfileData);
        }
    }

    return null;
};

GraphqlPassportSignInContainer.propTypes = {
    brand: shape({
        domain: string,
    }),
    isPassportUser: bool,
    passportData: object,
    passportSignInData: func,
    featureFlags: object,
};

GraphqlPassportSignInContainer.defaultProps = {
    brand: {},
    isPassportUser: false,
    passportData: {},
    passportSignInData: () => {},
    featureFlags: {},
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    passportData: getPassportData(state),
    featureFlags: getFeatureFlags(state),
    isPassportUser: getIsPassportUserLoggedIn(state),
});
const mapDispatchToProps = (dispatch) => ({
    passportSignInData: bindActionCreators(setPassportSignInData, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GraphqlPassportSignInContainer));
